<template>
    <div data-v-05f51dd9="" class="tuikeOrder">
        <div class="firstOrderTab">
            <tabs class="secondaryOrder" :active="tabActive"  @change="onClickTab">
                    <tab   v-for="tab in tabList" :title="tab.name" :name="tab.id" :key="tab.id">
                        <div class="orderTab1">
                            <List :list="list" :loading="loading"/>
                        </div>
                    </tab>

            </tabs>
        </div>

    </div>
</template>
<script>
    import {Tabs,Tab} from "vant"
    import List from "./components/List"
    import {elecOrderList} from "@/service/electricity"
    export default {
        components:{
            Tabs,
            Tab,
            List
        },
        data(){
            return {
                list:[],
                tabList:[
                    {
                        id:'',
                        name:'全部'
                    },
                    {
                        id:1,
                        name:'待付款'
                    },
                    {
                        id:3,
                        name:'充值成功'
                    },
                    {
                        id:4,
                        name:'充值失败'
                    },
                ],
                tabActive:'',
                totalList:[],
                loading:true
            }
        },
        methods:{
            async getListData(status=1) {
                let data = await elecOrderList({status})
                this.list = data
                this.totalList=data
                this.loading=false
                console.log(this.loading)
            },
            onClickTab(status){
                this.tabActive=status;
                console.log(status)
                if(status===''){
                    console.log(this.totalList)
                    this.list=  this.totalList
                    return
                }

                this.list=this.totalList.filter(i=>i.status===status)
            }
        },
        mounted(){
            this.getListData()

        }
    }
</script>
<style >
    .tuikeOrder {
        background-color: #f6f7f7;
        min-height: 100vh;
    }

    .tuikeOrder .van-hairline--top-bottom:after {
        border: none;
    }

    .tuikeOrder .search {
        padding: 0.400001rem 0.6rem 0;
    }

    .tuikeOrder .search .searchImg {
        width: 0.56rem;
        height: 0.56rem;
        margin-top: 0.2rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAADs0lEQVRIS7WWf2gcRRTHv2/2rkET0hhszKK1pWgtVhSEKIUKFtQqVdo/NFaKIN7tzCUHQSOIouAiWCjRE0Ka29lLE5CCoIK0ikoK9Qf2n/5RFawiKIIaT1rFWg3mctl98o67cLmmOS/W+WOXu3kzn/m+N++9JTQZ1tpkHMe3OY6znpl7lFLnmPnnubm5k0NDQ+ebrZd5uphREAQ3EtGzAO4H0LWMXQnAhwBeNcZMrwS7ADI6OtrW1tb2CgADIFFd/CsRfc3MZ5j5CiLaBGBj3cYCecwYU1wOtgQyOTm5rlwuHwGwrWp8VCn18szMzAnf9+P6DcIw3CoHYWY5zBoAPwHYbYw51QhahFQViHwBiN/3ZTKZ95r53Fq7BcDbAOR9BkCfMeaH+nWLkDAMDzLzoACIaLvW+nQzQG3eWrsWwMcAbgFwqlgs9tUrr0BEOjN/QUQKwC6t9fv/FlCzGxsb25BMJj8DIDFLaa0na3M1yGFxDxEd0VrvaRVQp+hpAAcAfG+MkctRGeT7/hrXdcWXa+M43j4wMHBitZCRkZH2zs5O2etyx3FuTafTogxUKBTuiOP4EwlasVh0G29Rq0Br7VEADxDRM1prUQUKw3AfMx+WwBlj7mx100b7IAj2SxIz83gmk8lWINbaJwHkiOgNrfXD/xVirX1CqgARvaW1fqim5HFmPgRg2hiz8xJAXgDgA5gwxng1yE5m/gDAd8aY6y4B5DUAjzLzi5lMRoCgqamprvn5ebkRySiKtgwODn6zWpDv+8p13V8ArFNK3eN53rEKRB7WWvlxFxHltNZPrRYSBMGDRPQmEUlZ6jHGlOshuwC8C6DkOM5N6XT621ZBuVzusvb29s8BbAbwkjHm+SUZX1VzHMAOAKcTicS2VCr1Zysga+3rAPZKvpVKpevrG9pigSwUCtfEcXwSgCtFTim12/M8Kd8rDlHQ0dFxiJkfAVBWSt3reZ4ceHEs6SdhGPYxs5T3KwH8JrJnZ2eD4eHhvxtJEuTe3t49RLQfwA0CICLJkXK5XH4nm83KBaiMCzrj+Pj4JsdxpD/cXLX5i5mPEdGXzHyWiLqkMzLzfQCuqtqcVUrtjaJoMxHlJR0WFhZ2ZLPZH5eFyJ++7ydc100x83NEtH4Ff51n5oNEdMAY80c+n79aKSUFdkM96KIfEjWlExMTfVEU3Q3gWgA9zHzOcZyZKIo+7e7uPt7f3z9ff4h8Pr9RKfVRFfSVMWZrM0izuC87XwVNE9HvWuvb/xdII/kfM/x9Kb5pOHgAAAAASUVORK5CYII=) no-repeat;
        background-size: 100% 100%;
    }

    .tuikeOrder .search .van-search__content {
        border-radius: 0.84rem;
    }

    .tuikeOrder .firstOrderTab .van-tab {
        color: #666;
    }

    .tuikeOrder .firstOrderTab .van-tabs__wrap {
        width: 100vw;
        height: 1.68rem;
        background-color: #fff;
    }

    .tuikeOrder .firstOrderTab .van-tab--active {
        color: #333;
        font-size: 0.6rem;
        font-weight: 600;
    }

    .tuikeOrder .firstOrderTab .van-tabs__line {
        width: 1.2rem !important;
        height: 0.159999rem;
        background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
        background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
        background: linear-gradient(90deg, #f0d19e, #e6ba79);
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .van-tabs__wrap {
        width: auto;
        padding: 0.6rem;
        height: 1.2rem;
        background: none;
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .van-tabs__nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .van-tab {
        width: 3.159999rem;
        color: #666;
        font-size: 0.48rem;
        border-radius: 0.6rem;
        background: #fff;
        border: 0.02rem solid transparent;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .van-tab--active {
        font-size: 0.560001rem;
        font-weight: 500;
        background: #fff9f2;
        border: 0.02rem solid #e6ba79;
        color: #5a3309;
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .van-tabs__line {
        display: none;
    }

    .tuikeOrder .firstOrderTab .secondaryOrder .corner {
        position: absolute;
        top: 0.02rem;
        right: 0.319999rem;
        width: 0.559999rem;
        height: 0.559999rem;
        background-color: #fd5c59;
        border-radius: 50%;
        color: #fff;
        text-align: center;
    }

    .tuikeOrder .van-loading {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: calc(100vh - 1.92rem);
    }

    .tuikeOrder .van-loading__spinner {
        position: relative;
    }

    .tuikeOrder .orderTab1 {
        padding: 0.6rem;
    }

    .tuikeOrder .orderTab {
        height: calc(100vh - 1.92rem);
    }

    .tuikeOrder .van-tabs__nav {
        background-color: transparent;
    }

    .tuikeOrder .orderItem {
        padding: 0.400005rem;
        background-color: #fff;
        border-radius: 0.12rem;
    }

    .tuikeOrder .orderItem .itemTitle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #999;
        font-size: 0.48rem;
        padding-bottom: 0.40005rem;
    }

    .tuikeOrder .orderItem .itemTitle .itemTime {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left;
        color: #333;
        font-size: 0.56001rem;
    }

    .tuikeOrder .orderItem .itemTitle .itemStates {
        color: #e6bb7a;
        font-size: 0.559999rem;
        margin-left: 1.4rem;
    }

    .tuikeOrder .orderItem .itemCon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0;
    }

    .tuikeOrder .orderItem .itemCon .itemImg {
        display: inline-block;
        width: 3.400005rem;
        height: 3.400005rem;
        vertical-align: middle;
        border-radius: 0.36rem;
        border: 0.02rem solid #f2f3f5;
    }

    .tuikeOrder .orderItem .itemCon .itemText {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.20001rem;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemTextWrap {
        display: inline-block;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemName {
        width: 7.08rem;
        font-size: 0.56rem;
        color: #333;
        font-weight: 400;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .tuikeOrder .orderItem .itemCon .itemText .skuName {
        display: inline-block;
        font-size: 0.48rem;
        color: #999;
        font-weight: 400;
    }

    .tuikeOrder .orderItem .itemCon .itemText .channelIdType {
        margin-top: 0.02rem;
        font-size: 0.48rem;
        color: #999;
        font-weight: 400;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemNumDa {
        float: right;
        color: #daa456;
        display: inline-block;
        vertical-align: top;
        padding-top: 0.04rem;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemNumDa .itemPrice {
        margin-left: -0.02rem;
        color: #333;
        font-size: 0.6rem;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemNumDa .itemPrice > span {
        font-size: 0.400005rem;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemNumDa .itemNum {
        margin-top: 0.12rem;
        font-size: 0.48rem;
        color: #999;
        text-align: right;
    }

    .tuikeOrder .orderItem .itemCon .itemText .itemNumDa:after {
        content: "";
        display: block;
        clear: both;
    }

    .tuikeOrder .orderItem .actualPayment {
        color: #333;
        font-weight: 500;
        font-size: 0;
        text-align: right;
    }

    .tuikeOrder .orderItem .actualPayment > span:first-child {
        margin-right: 0.20001rem;
        font-size: 0.6rem;
    }

    .tuikeOrder .orderItem .actualPayment > span:nth-child(2) {
        font-size: 0.48rem;
    }

    .tuikeOrder .orderItem .actualPayment > span:nth-child(3) {
        font-size: 0.6rem;
    }

    .tuikeOrder .orderItem .itemBtn {
        text-align: right;
        margin-top: 0.680001rem;
    }

    .tuikeOrder .orderItem .itemBtn .btn {
        width: 3rem;
        height: 1.2rem;
        text-align: center;
        font-size: 0.48rem;
        padding: 0;
        border-radius: 0.6rem;
    }

    .tuikeOrder .orderItem .itemBtn .btn1 {
        border: 0.02rem solid #ebedf0 !important;
    }

    .tuikeOrder .orderItem .itemBtn .btn + .btn {
        margin-left: 0.319995rem;
    }

    .tuikeOrder .orderItem .itemBtn .payment {
        background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
        background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
        background: linear-gradient(90deg, #f0d19e, #e6ba79);
        color: #68400b;
    }

    .tuikeOrder .orderItem + .orderItem {
        margin-top: 0.400005rem;
    }

    .tuikeOrder .searchPopup {
        padding-bottom: 0.6rem;
    }

    .tuikeOrder .searchHistoryWrap {
        padding: 0.12rem 0.6rem 0.12rem 0.52rem;
    }

    .tuikeOrder .searchHistoryWrap .title {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #333;
        font-size: 0.6rem;
        font-weight: 700;
        padding-bottom: 0.16rem;
    }

    .tuikeOrder .searchHistoryWrap .title,
    .tuikeOrder .searchHistoryWrap .title .colseWarp {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .tuikeOrder .searchHistoryWrap .title .colseWarp .titleColse {
        width: 0.64rem;
        height: 0.64rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACe0lEQVRYR+2XT4hNcRTHv+d3H14WFizM86fIoEkWlmM1klnIQlHESMy8e35TvBhKotxSkgwa6t3f7WVKIjt2RCYWkmRhIyw0xWMzFmbzHvf9ju50X03MvHffvFdS7+xu9/y+53PP+d3f7xzCPzZKEt/zvPkdHR03iWg3gAUJ1rxPpVJ9/f39r+v5JgIIguCAiNwCMElEk7VERcQBsFREXmqtu1sC4Pv+WSI6D+A0M1+sJVooFBZXKpUJAJ+ZeWUiAGNMRim1bDZna20fgGMArimlbtcRXWitfU5E40S0q4bvr2w2+3aqBMaYb1Ha6tG2+r1SaucUQBAEwyKythqAiJaIyGYAXwC8aSbwbFpEZMMwHJpxE+bz+R6l1BiAO8y8vxmAelptgEQZGBkZWZROpw+VSqXRXC73o1oSY8xGEdmktY7OiCnL5/M7HMeZdF33Wfxcs5yJAIwxRwBcB3CUmW9MA3gEoNdxnFUDAwPj8R9VAvCVmVe3EuAEgMsATjLzcBUgCIIxEekhovWu636IAYSIiq7rLm8DtDPQzkA7A+0MtDIDxwFcATDEzFen3QVPAWwRkXVa64/xUWwBFJl5RcsACoVCZxiGlwCcqgaKxH3f36OU6i0Wi+x5Xhh3VxdEZKJ6Z/z3Dck9Zt7bTEvm+/5WInoiIne11vv+1JqxH/B9v5uIXgD4VC6Xu3K5XHmuEMaYcwA8AKPMfDgRgDFmHoB3ANYQ0SsReQCgYQgi6rLWHlRKOdba7Vrrh4kA4s20AcB9Eemc69fH634S0RnXdaOG5i+rORtGQ2kmk9kWQRBRqhEQIpJKpfIdwOPBwcFovpjREg2njQRu1Pc3jF1cQzYPC4kAAAAASUVORK5CYII=) no-repeat;
        background-size: 100% 100%;
        margin-right: 0.24rem;
    }

    .tuikeOrder .searchHistoryWrap .title .colseWarp .colseText {
        font-size: 0.48rem;
        color: #999;
    }

    .tuikeOrder .searchHistoryWrap .seacrchCon_item {
        display: inline-block;
        height: 1.2rem;
        color: #333;
        line-height: 1.2rem;
        background-color: #f2f2f2;
        margin-top: 0.48rem;
        margin-right: 0.64rem;
        padding: 0.48rem;
        border-radius: 0.6rem;
    }
    .tuikeOrder .notList{
        margin-top: 3rem;
    }
    .tuikeOrder .notList .notListImg {
        width: 100vw;
        height: 9.43995rem;
        /*background: url(/resources/images/wudingdan-82a9bf67b8.png) no-repeat;*/
        background-size: 100% 100%;
        margin-left: -.48rem;
    }

    .tuikeOrder .notList .text {
        margin-top: 0.3999rem;
        font-size: 0.559995rem;
        color: #666;
        text-align: center;
    }

    .tuikeOrder .notList .btnWarp {
        margin-top: 0.8rem;
        text-align: center;
    }

    .tuikeOrder .notList .btnWarp .btn {
        width: 6.36rem;
        height: 1.519995rem;
        background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
        background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
        background: linear-gradient(90deg, #f0d19e, #e6ba79);
        border-radius: 0.24rem;
        color: #68400b;
        font-size: 0.63999rem;
    }

    .tuikeOrder .codeModal {
        background: none;
        height: 13.5rem;
    }

    .tuikeOrder .codeModal .close {
        width: 1.8rem;
        height: 1.8rem;
        position: absolute;
        bottom: 0.9rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .tuikeOrder .codeContent {
        background: #fff;
        width: 11.25rem;
        height: 6.6rem;
        position: relative;
        border-radius: 0.3rem;
        padding: 0.15rem 0.45rem 0.6rem;
    }

    .tuikeOrder .codeContent .title {
        text-align: center;
        line-height: 1.5rem;
        color: #333;
        font-size: 0.6rem;
    }

    .tuikeOrder .codeContent .codeListcenter {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 7.5rem;
    }

    .tuikeOrder .codeContent .codeList .codeItem {
        background: #f8f8f8;
        border-radius: 0.09rem;
        padding: 0.45rem 0.225rem;
        margin-bottom: 0.225rem;
    }

    .tuikeOrder .codeContent .codeList .codeItem .titleCode {
        /*font-size: #333;*/
        font-size: 0.51rem;
    }

    .tuikeOrder .codeContent .codeList .codeItem .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0.75rem;
        margin-top: 0.45rem;
    }

    .tuikeOrder .codeContent .codeList .codeItem .card span {
        color: #999;
    }

    .tuikeOrder .codeContent .codeList .codeItem .card p {
        color: #333;
        width: 6.9rem;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .tuikeOrder .codeContent .codeList .codeItem .card .cardIn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .tuikeOrder .codeContent .codeList .codeItem .password {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 0.75rem;
        margin-top: 0.6rem;
    }

    .tuikeOrder .codeContent .codeList .codeItem .password span {
        color: #999;
    }

    .tuikeOrder .codeContent .codeList .codeItem .password p {
        color: #333;
        width: 6.9rem;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .tuikeOrder .codeContent .codeList .codeItem .password .passwordIn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .tuikeOrder .codeContent .codeList .codeItem .copy {
        color: #5a3309 !important;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        line-height: 1.05rem;
        height: 1.05rem;
        padding: 0.45rem;
        border-radius: 1.05rem;
        background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
        background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
        background: linear-gradient(90deg, #f0d19e, #e6ba79);
    }
</style>
