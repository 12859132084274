
import axios from "../utils/axios"

export function getProductList() {
  return  axios.get("/mobile_api/mobile/product_list")
}

export function orderSubmit(params) {
  return  axios.post("/mobile_api/mobile/new_pay",params)
}
export function orderReSubmit(params) {
  return  axios.post("/mobile_api/mobile/re_order",params)
}

export function orderList(params) {
  return  axios.get("/mobile_api/mobile/order_list?mobile="+params.mobile,params)
}
