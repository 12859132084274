<template>
   <div>
      <div v-if="!loading">
          <div class="notList" v-if="!list.length">
              <div class="text">暂时还没有订单记录哦～</div>
          </div>
          <div v-else>
              <div class="orderItem" v-for="item in list" :key="item.order_id">
                  <div class="itemTitle">
                      <div class="itemTime">充值时间：{{item.complete_time}}</div>
                      <div class="itemStates"
                           :style="{color:colorArr[item.status-1]}">
                          {{elec_order_status[item.status]}}
                      </div>
                  </div>
                  <div class="itemCon">
                      <img
                              :src="item.icon_path"
                              class="itemImg">
                      <div class="itemText">
                          <div class="itemTextWrap">
                              <div class="itemName">{{item.product_name}}</div>
                              <!----> <!----></div>
                          <div class="itemNumDa">
                                                     <div>
<!--                                                         <span>共支付:</span>-->
                                                         <span class="itemPrice"><span style="color:red;">共支付：</span><span>¥</span>{{item.price/100}}</span>
                                                     </div>
                              <div class="itemNum">x1</div>
                          </div>
                      </div>
                  </div>
                  <div class="actualPayment" v-if="item.status>1"><span>实付款</span> <span>¥</span>
                      <span>{{item.price/100}}</span></div>
                  <div class="itemBtn"><!---->
                      <van-button class="btn btn1 " @click="gotoDetail(item.order_id)">查看详情</van-button>
                      <van-button v-if="item.status===1" class="btn payment" @click="goPay(item)">付款</van-button>
                  </div>
              </div>
          </div>
      </div>
   </div>
</template>
<script>
    import {elec_order_status} from "@/utils/enum"
    import {Button,Dialog} from "vant"
    import {orderReSubmit} from "../../../service/recharge";
    export default {
        components:{
            VanButton:Button,
        },
        data(){
            return {
                elec_order_status,
                colorArr:[
                    "blue",'green','green','gray'
                ]
            }
        },
        props:{
            list:{
                type:Array,
                default:()=>[]
            },
            loading: {
                type:Boolean,
                default:true
            },
        },
        methods:{
            gotoDetail(id){
                this.$router.push({
                    path:'/order_detail/'+id,
                })
            },
            async goPay(item) {
                Dialog.confirm({
                    title: '确定付款么',
                })
                    .then(async () => {
                        // on confirm
                        console.log("confirm")
                        let pay_method=null
                        if (navigator.userAgent.match(/MicroMessenger/i)) {
                            pay_method = 1
                        }
                        item.pay_method=pay_method
                        /* eslint-disable */
                        debugger
                        //this.submitData.pay_method = pay_method;
                        let {config} = await orderReSubmit(item)
                        if (config.pay_url) {
                            window.location.href = config.pay_url
                            return
                        }
                        //this.wcPay(config)
                    })
                    .catch(() => {
                        // on cancel
                        console.log("cancel")
                    });

            }
        }
    }
</script>
<style>

</style>
